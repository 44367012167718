// RusoOne

@font-face {
  font-family: 'RussoOne';
  font-weight: 400;
  src: url('~static/fonts//RussoOne/RussoOne-Regular.woff2') format('woff2');
}

// Zekton

@font-face {
  font-family: 'Zekton';
  font-weight: 300;
  src: url('~static/fonts/Zekton/Zekton-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Zekton';
  font-weight: 400;
  src: url('~static/fonts/Zekton/Zekton-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Zekton';
  font-weight: 700;
  src: url('~static/fonts/Zekton/Zekton-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Zekton';
  font-weight: 900;
  src: url('~static/fonts/Zekton/Zekton-Heavy.woff2') format('woff2');
}

// Inter

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url('~static/fonts/Inter/Inter-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url('~static/fonts/Inter/Inter-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('~static/fonts/Inter/Inter-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('~static/fonts/Inter/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('~static/fonts/Inter/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('~static/fonts/Inter/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('~static/fonts/Inter/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url('~static/fonts/Inter/Inter-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: url('~static/fonts/Inter/Inter-Black.woff2') format('woff2');
}
