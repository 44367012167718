@import '~static/styles/mixins';
@import '~static/styles/variables';

.App {
  min-height: 100%;
  width: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;

  @include respond-above(md) {
    padding: 40px 80px;
  }

  background: url('~static/images/stars-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .logo {
      display: flex;
      align-items: center;
      margin: 0;

      .icon {
        width: 40px;
        height: auto;
        margin: 0 10px 0 0;
      }

      .label {
        font-size: 24px;
        font-family: $russo-one;
        color: $green;
      }
    }
  }

  .main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    margin: 100px 0;

    @include respond-above(lg) {
      margin: 140px 0;
    }

    .card {
      display: flex;
      align-items: center;
      flex-direction: column;

      padding: 70px 50px;
      box-shadow: 0 4px 24px -1px #00000040;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-bottom: none;
      border-radius: 20px;
      max-width: 720px;
      width: 100%;

      @supports (backdrop-filter: blur(6px)) {
        backdrop-filter: blur(6px);
        background: linear-gradient(rgba(255, 255, 255, 0.17), rgba(238, 244, 239, 0.06) 120.84%);
      }

      @supports not (backdrop-filter: blur(6px)) {
        background: linear-gradient(rgba(255, 255, 255, 0.82), rgba(238, 244, 239, 0.32) 120.84%);
      }

      .title {
        font-size: 32px;
        margin: 0 0 20px;
        font-family: $russo-one;
        text-align: center;

        span {
          color: $green;
        }
      }

      .connectButton,
      .disconnectButton {
        margin: 0 0 40px;
        width: 100%;
      }

      .connectButton {
        max-width: 240px;
      }

      .disconnectButton {
        max-width: 280px;
      }

      .switcher {
        margin: 0 0 40px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        @include respond-above(md) {
          grid-template-columns: 1fr auto 1fr;
        }

        .input {
          pointer-events: none;
        }

        .switch {
          position: relative;
          width: 50px;
          height: 50px;
          padding: 0;
          border-radius: 50%;
          margin: 0 auto;

          @include respond-above(md) {
            margin: auto 0 0;
          }

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 30px;

            margin: -15px -15px;
          }

          .arrow,
          .arrows {
            transition: all 0.2s ease-in-out;

            @include respond-below(md) {
              transform: rotate(90deg);
            }
          }

          &:hover .arrow {
            opacity: 0;
          }

          &:not(:hover) .arrows {
            opacity: 0;
          }
        }
      }

      .form {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        width: 100%;
      }
    }
  }

  .footer {
    margin: auto 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .text {
      font-family: $zekton;
      font-weight: 700;
      margin: 0;
    }
  }
}
