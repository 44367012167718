@import '~static/styles/variables';

.Spinner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
  color: $green;
  margin: auto;

  circle {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  &.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}

.bluredOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 60px;
  padding: 0;
  background: rgba($white, 0.3);
  border-radius: 10px;
  backdrop-filter: blur(20px);

  display: flex;
  align-items: center;
  justify-content: center;

  .Spinner {
    width: 60px;
    height: 60px;

    flex-shrink: 1;
  }
}
