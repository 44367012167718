// Fonts
$inter: 'Inter', sans-serif;
$zekton: 'Zekton', sans-serif;
$russo-one: 'RussoOne', sans-serif;

// Colors
$white: #ffffff;
$light-gray: #f0f5f090;
$black: #0d0d19;
$green: #7fd959;
$red: #ff5447;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

// Layers
$modal-overlay-layer: 100;
$modal-content-layer: 101;
$menu-overlay-layer: 10;
$menu-content-layer: 11;
$dropdown-layer: 1000;
