@import '~static/styles/mixins';
@import '~static/styles/variables';

.FeedbackModal {
  padding: 16px;
  margin: 20px 0 auto;
  max-width: 360px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    font-family: $zekton;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .icon {
    width: 24px;
    height: auto;
    margin: 0 10px 0 0;
    flex-shrink: 0;

    &.error {
      color: $red;
    }

    &.success {
      color: $green;
    }
  }
}
