@import '~static/styles/mixins';
@import '~static/styles/variables';

.overlay {
  position: fixed;
  flex: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: $modal-overlay-layer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0;
  background: rgba($black, 0.8);

  @supports (backdrop-filter: blur(4px)) {
    backdrop-filter: blur(4px);
  }

  @include respond-above(md) {
    padding: 80px 0;
  }

  @include respond-above(xxl) {
    padding: 100px 0;
  }

  &.unwrapped {
    background: rgba($black, 0.9);
  }

  &.noClose {
    padding: 20px 0;
  }
}

.card {
  max-width: calc(100% - 30px);
  z-index: $modal-content-layer;

  padding: 30px;
  box-shadow: 0 4px 24px -1px #00000040;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: none;
  border-radius: 20px;
  max-width: 720px;
  width: 100%;

  &.unwrapped {
    padding: 0;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: $white;

  @include respond-above(md) {
    top: 30px;
    right: 30px;
  }

  @include respond-above(xxl) {
    top: 40px;
    right: 40px;
  }
}
